import React from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/SEO"
import {
  FaFacebookSquare,
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaFileContract,
  FaMap
} from "react-icons/fa"
import { Link } from "gatsby"
import GoogleMapReact from 'google-map-react';

const renderMarker = ({ map, maps }: {
  map: any;
  maps: any;
  ref: Element | null;
}) => {
  new maps.Marker({
    position: { lat: 52.8213591, lng: -0.0032764 },
    map
  })
}

const ContactUsPage: React.FC = () => (
  <Layout activePage="contact-us">
    <SEO title="Contact Us" />
    <h1 className="page-title">Contact Us</h1>
    <div className="divider divider--spacer-bottom" />
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <p className="no-spacing">
          <b>Greenfield Farm</b>
          <br />
          Washway Road
          <br />
          Holbeach
          <br />
          Spalding
          <br />
          Lincolnshire
          <br />
          PE12 8AH
        </p>
        <a
          className="contact-link contact-link--map"
          target="_blank"
          rel="noopener noreferrer"
          href="https://goo.gl/maps/jWaL42U6cehdkDtBA"
        >
          <FaMap />
          Find us on Google Maps
        </a>
        <a
          className="contact-link contact-link--facebook"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/greenfieldpottery/"
        >
          <FaFacebookSquare />
          Find us on Facebook
        </a>
        <a
          className="contact-link contact-link--instagram"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/greenfield_pottery/"
        >
          <FaInstagram />
          Our Instagram
        </a>
        <a className="contact-link" href="mailto:enquiries@greenfieldpottery.co.uk">
          <FaEnvelope />
          enquiries@greenfieldpottery.co.uk
        </a>
        <a className="contact-link" href="tel:01406425866">
          <FaPhone />
          01406 425 866
        </a>
        <Link className="contact-link" to="/terms-and-conditions">
          <FaFileContract />
          Terms and Conditions
        </Link>
      </div>

      <div style={{ paddingLeft: 30, paddingTop: 0, height: 400, width: "100%", maxWidth: 800 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAFeHF4Gm0BdYwRDyUaBZ7czHrHPtZAJ2M" }}
          defaultCenter={{
            lat: 52.8213591,
            lng: -0.0055764
          }}
          margin={[20]}

          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={renderMarker}
          defaultZoom={15}
        >

        </GoogleMapReact>
      </div>
    </div>

  </Layout>
)

export default ContactUsPage
